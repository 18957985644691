import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import ModifierService from '~/src/services/ModifierService'
import { CartModifierGroupStateInterface } from '~/src/stores/cart'

export default class ModifierGroupService {
  private readonly _modifierGroups: Record<string, ModifierGroup>
  private readonly _modifiers: Record<string, Modifier>

  public constructor(
    modifierGroups: Record<string, ModifierGroup>,
    modifiers: Record<string, Modifier>,
  ) {
    this._modifierGroups = modifierGroups
    this._modifiers = modifiers

    return this
  }

  public preselectModifiersForCartState(modifierGroupIds: string[], time: Date): CartModifierGroupStateInterface[] {
    const cartModifierGroups: CartModifierGroupStateInterface[] = []

    this._addDefaultModifiersForGroups(cartModifierGroups, modifierGroupIds, time)

    return cartModifierGroups
  }

  private _addDefaultModifiersForGroups(
    cartModifierGroups: CartModifierGroupStateInterface[],
    modifierGroupIds: string[],
    time: Date
  ): void {
    modifierGroupIds.forEach((modifierGroupId: string) => {
      if (!this._modifierGroups[modifierGroupId]) {
        return
      }

      this._modifierGroups[modifierGroupId].modifiers.forEach((modifierId: string) => {
        if (!this._modifiers[modifierId] || !('type' in this._modifiers[modifierId])) {
          return
        }

        const modifierService = new ModifierService(this._modifiers[modifierId])

        if (
          !this._modifiers[modifierId].isDefault
          || !this._modifiers[modifierId].inStock
          || this._modifiers[modifierId].isLocked
          || modifierService.isModifierSnoozed(time)
        ) {
          return
        }

        let inStateIndex = cartModifierGroups.findIndex((mg: CartModifierGroupStateInterface) => mg.id === modifierGroupId)
        let modifierInStateIndex

        if (inStateIndex === -1) {
          inStateIndex = cartModifierGroups.length
          modifierInStateIndex = 0
          cartModifierGroups.push({
            id: modifierGroupId,
            modifiers: [{
              id: modifierId,
              type: this._modifiers[modifierId].type,
              quantity: 1,
              action: 'pre',
              modifierGroups: []
            }]
          })
        } else {
          modifierInStateIndex = cartModifierGroups[inStateIndex].modifiers.length
          cartModifierGroups[inStateIndex].modifiers.push({
            id: modifierId,
            type: this._modifiers[modifierId].type,
            quantity: 1,
            action: 'pre',
            modifierGroups: []
          })
        }

        this._addDefaultModifiersForGroups(
          cartModifierGroups[inStateIndex].modifiers[modifierInStateIndex].modifierGroups,
          this._modifiers[modifierId].modifierGroups || [],
          time
        )
      })
    })
  }
}
