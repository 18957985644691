import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import ModifierGroupService from '~/src/services/ModifierGroupService'
import { CartModifierGroupStateInterface, CartModifierStateInterface } from '~/src/stores/cart'

export default class CartModifierGroupStateService {
  private readonly _cartModifierGroups: CartModifierGroupStateInterface[]
  private readonly _modifiers: Record<string, Modifier>
  private readonly _modifierGroups: Record<string, ModifierGroup>
  private readonly _time: Date
  private readonly _modifierGroupService: ModifierGroupService

  public constructor(
    cartModifierGroups: CartModifierGroupStateInterface[],
    modifierGroups: Record<string, ModifierGroup>,
    modifiers: Record<string, Modifier>,
    time: Date
  ) {
    this._cartModifierGroups = [ ...cartModifierGroups ]
    this._modifierGroups = modifierGroups
    this._modifiers = modifiers
    this._time = time
    this._modifierGroupService = new ModifierGroupService(modifierGroups, modifiers)
  }

  public validateGroups(cartModifierGroups: CartModifierGroupStateInterface[], modifierGroupIds: string[]): boolean {
    for (const modifierGroupId of modifierGroupIds) {
      if (!this._modifierGroups[modifierGroupId]) {
        return false
      }

      const mg = cartModifierGroups.find((mg: CartModifierGroupStateInterface) => mg.id === modifierGroupId)

      if (!mg && this._modifierGroups[modifierGroupId].isRequired) {
        return false
      }

      const cartModifierGroupService = new CartModifierGroupStateService(
        cartModifierGroups,
        this._modifierGroups,
        this._modifiers,
        this._time
      )

      if (!cartModifierGroupService.isGroupMinSelectionReached(this._modifierGroups[modifierGroupId])) {
        return false
      }

      // Validating sub-modifiers
      if (mg?.modifiers.length) {
        for (const modifier of mg.modifiers) {
          if (!this.validateGroups(
            modifier.modifierGroups,
            this._modifiers[modifier.id].modifierGroups || []
          )) {
            return false
          }
        }
      }
    }

    return true
  }

  public isGroupMaxSelectionReached(group: ModifierGroup): boolean {
    return this._isGroupSelectionReached(group, 'multipleChoiceMax')
  }

  public isGroupMinSelectionReached(group: ModifierGroup): boolean {
    return this._isGroupSelectionReached(group, 'multipleChoiceMin')
  }

  public isGroupModifierMaxSelectionReached(group: ModifierGroup, modifierId: string): boolean {
    if (!group.isMultipleChoice || !group.multipleChoiceMultiselectMax) {
      return false
    }

    const mg = this._findGroup(group.id)

    if (!mg) {
      return false
    }

    const cartModifier = mg.modifiers.find((cartModifier: CartModifierStateInterface) => cartModifier.id === modifierId)

    if (!cartModifier) {
      return false
    }

    return cartModifier.quantity >= group.multipleChoiceMultiselectMax
  }

  public removeModifierFromGroup(group: ModifierGroup, modifierId: string): CartModifierGroupStateInterface[] {
    const groupIndex = this._findGroupIndex(group.id)

    if (groupIndex === -1) {
      return this._cartModifierGroups
    }

    this._cartModifierGroups[groupIndex] = { ...this._cartModifierGroups[groupIndex] }
    this._cartModifierGroups[groupIndex].modifiers = [ ...this._cartModifierGroups[groupIndex].modifiers ]

    const modifierIndex = this._cartModifierGroups[groupIndex].modifiers
      .findIndex((m: CartModifierStateInterface) => m.id === modifierId)

    if (modifierIndex === -1) {
      return this._cartModifierGroups
    }

    if (group.isMultipleChoice && this._modifiers[modifierId].isDefault) {
      this._cartModifierGroups[groupIndex].modifiers.splice(
        modifierIndex,
        1,
        {
          ...this._cartModifierGroups[groupIndex].modifiers[modifierIndex],
          quantity: 0,
          action: 'rem'
        }
      )
    } else {
      this._cartModifierGroups[groupIndex].modifiers.splice(
        modifierIndex,
        1
      )
    }



    return this._cartModifierGroups
  }

  public addModifierToGroup(group: ModifierGroup, modifier: Modifier, quantity: number): CartModifierGroupStateInterface[] {
    const groupIndex = this._findGroupIndex(group.id)

    if (groupIndex === -1) {
      this._cartModifierGroups.push({
        id: group.id,
        modifiers: [
          {
            id: modifier.id,
            type: modifier.type,
            quantity,
            modifierGroups: [],
            action: modifier.isDefault ? 'pre' : 'add'
          }
        ]
      })

      return this._cartModifierGroups
    }

    this._cartModifierGroups[groupIndex] = { ...this._cartModifierGroups[groupIndex] }
    this._cartModifierGroups[groupIndex].modifiers = [ ...this._cartModifierGroups[groupIndex].modifiers ]

    const modifierIndex = this._cartModifierGroups[groupIndex].modifiers
      .findIndex((m: CartModifierStateInterface) => m.id === modifier.id)

    if (modifierIndex === -1) {
      this._cartModifierGroups[groupIndex].modifiers.push({
        id: modifier.id,
        type: modifier.type,
        action: 'add',
        quantity,
        modifierGroups: this._modifierGroupService
          .preselectModifiersForCartState(this._modifiers[modifier.id].modifierGroups || [], this._time)
      })

      return this._cartModifierGroups
    }

    this._cartModifierGroups[groupIndex].modifiers[modifierIndex] = {
      ...this._cartModifierGroups[groupIndex].modifiers[modifierIndex],
      quantity,
      action: modifier.isDefault ? 'pre' : 'add'
    }

    return this._cartModifierGroups
  }

  public setModifierToGroup(group: ModifierGroup, modifier: Modifier): CartModifierGroupStateInterface[] {
    const groupIndex = this._findGroupIndex(group.id)

    if (groupIndex === -1) {
      this._cartModifierGroups.push({
        id: group.id,
        modifiers: [
          {
            id: modifier.id,
            type: modifier.type,
            quantity: 1,
            action: modifier.isDefault ? 'pre' : 'add',
            modifierGroups: this._modifierGroupService
              .preselectModifiersForCartState(this._modifiers[modifier.id].modifierGroups || [], this._time)
          }
        ]
      })

      return this._cartModifierGroups
    }

    this._cartModifierGroups[groupIndex] = {
      ...this._cartModifierGroups[groupIndex],
      modifiers: [
        {
          id: modifier.id,
          type: modifier.type,
          quantity: 1,
          action: modifier.isDefault ? 'pre' : 'add',
          modifierGroups: this._modifierGroupService
            .preselectModifiersForCartState(this._modifiers[modifier.id].modifierGroups || [], this._time)
        }
      ]
    }

    return this._cartModifierGroups
  }

  private _isGroupSelectionReached(group: ModifierGroup, minMax: 'multipleChoiceMin'|'multipleChoiceMax'): boolean {
    if (!group.isMultipleChoice || !group[minMax]) {
      return minMax === 'multipleChoiceMin'
    }

    const modifierGroup = this._findGroup(group.id)

    if (!modifierGroup) {
      return false
    }

    const total = modifierGroup.modifiers
      .filter((cartModifier: CartModifierStateInterface) => this._modifiers[cartModifier.id].ingredientType !== 'main')
      .map((modifier: CartModifierStateInterface) => modifier.quantity)
      .reduce((a: number, b: number) => a + b, 0)

    return total >= group[minMax]!
  }

  private _findGroup(groupId: string): CartModifierGroupStateInterface | undefined {
    return this._cartModifierGroups.find((mg: CartModifierGroupStateInterface) => mg.id === groupId)
  }

  private _findGroupIndex(groupId: string): number {
    return this._cartModifierGroups.findIndex((mg: CartModifierGroupStateInterface) => mg.id === groupId)
  }
}
