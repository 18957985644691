import { Modifier, ModifierGroup } from '@eo-storefronts/eo-core'
import { useEoValue } from '~/src/hooks/useEoState'
import CartModifierGroupStateService from '~/src/services/CartModifierGroupStateService'
import { CartModifierGroupStateInterface } from '~/src/stores/cart'
import { CHECKOUT_TIME_STATE } from '~/src/stores/checkout'
import { MODIFIER_GROUPS_STATE } from '~/src/stores/modifier-groups'
import { MODIFIERS_STATE } from '~/src/stores/modifiers'

interface ReturnsType {
  isGroupMinSelectionReached(cartModifierGroups: CartModifierGroupStateInterface[], group: ModifierGroup): boolean,
  isGroupMaxSelectionReached(cartModifierGroups: CartModifierGroupStateInterface[], group: ModifierGroup): boolean,
  isGroupModifierMaxSelectionReached(cartModifierGroups: CartModifierGroupStateInterface[], group: ModifierGroup, modifierId: string): boolean,
  addModifierToGroup(cartModifierGroups: CartModifierGroupStateInterface[], group: ModifierGroup, modifier: Modifier, quantity: number): CartModifierGroupStateInterface[],
  setModifierToGroup(cartModifierGroups: CartModifierGroupStateInterface[], group: ModifierGroup, modifier: Modifier): CartModifierGroupStateInterface[],
  removeModifierFromGroup(cartModifierGroups: CartModifierGroupStateInterface[], group: ModifierGroup, modifierId: string): CartModifierGroupStateInterface[],
  validateGroups(cartModifierGroups: CartModifierGroupStateInterface[], modifierGroupIds: string[]): boolean,
}

const useCartModifierGroupsStateService = (): ReturnsType => {
  const modifierGroups = useEoValue(MODIFIER_GROUPS_STATE)
  const modifiers = useEoValue(MODIFIERS_STATE)
  const checkoutTime = useEoValue(CHECKOUT_TIME_STATE)

  const isGroupMaxSelectionReached = (
    cartModifierGroups: CartModifierGroupStateInterface[],
    group: ModifierGroup
  ): boolean => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      checkoutTime
    )

    return service.isGroupMaxSelectionReached(group)
  }

  const isGroupMinSelectionReached = (
    cartModifierGroups: CartModifierGroupStateInterface[],
    group: ModifierGroup
  ): boolean => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      checkoutTime
    )

    return service.isGroupMinSelectionReached(group)
  }

  const isGroupModifierMaxSelectionReached = (
    cartModifierGroups: CartModifierGroupStateInterface[],
    group: ModifierGroup,
    modifierId: string
  ): boolean => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      checkoutTime
    )

    return service.isGroupModifierMaxSelectionReached(group, modifierId)
  }

  const addModifierToGroup = (
    cartModifierGroups: CartModifierGroupStateInterface[],
    group: ModifierGroup,
    modifier: Modifier,
    quantity: number
  ): CartModifierGroupStateInterface[] => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      checkoutTime
    )

    return service.addModifierToGroup(group, modifier, quantity)
  }

  const setModifierToGroup = (
    cartModifierGroups: CartModifierGroupStateInterface[],
    group: ModifierGroup,
    modifier: Modifier
  ): CartModifierGroupStateInterface[] => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      checkoutTime
    )

    return service.setModifierToGroup(group, modifier)
  }

  const removeModifierFromGroup = (
    cartModifierGroups: CartModifierGroupStateInterface[],
    group: ModifierGroup,
    modifierId: string
  ): CartModifierGroupStateInterface[] => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      checkoutTime
    )

    return service.removeModifierFromGroup(group, modifierId)
  }

  const validateGroups = (
    cartModifierGroups: CartModifierGroupStateInterface[],
    modifierGroupIds: string[]
  ): boolean => {
    const service = new CartModifierGroupStateService(
      cartModifierGroups,
      modifierGroups,
      modifiers,
      checkoutTime
    )

    return service.validateGroups(cartModifierGroups, modifierGroupIds)
  }

  return {
    isGroupMinSelectionReached,
    isGroupMaxSelectionReached,
    isGroupModifierMaxSelectionReached,
    addModifierToGroup,
    setModifierToGroup,
    removeModifierFromGroup,
    validateGroups
  }
}

export default useCartModifierGroupsStateService
